




































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  onBeforeMount,
} from '@vue/composition-api';
import Axios from 'axios';
export default defineComponent({
  props: {
    multiple: Boolean,
    referido: Boolean,
  },
  setup(props, { root }) {
    const loading = ref(false);
    const formValues = ref({});
    const formReferido = ref({});

    const items = ref<any[]>([]);
    const itemsReferido = ref([
      {
        label: 'Agente',
        value: 'Agente',
      },
      {
        label: 'Colaborador',
        value: 'Colaborador',
      },
      {
        label: 'Cliente',
        value: 'Cliente',
      },
    ]);
    const rewrite = reactive<any>({});
    const messages = ref({
      required: 'Campo obligatorio',
      email: 'Email incorrecto',
      min: 'Minimo 8 digitos',
    });
    const products = ref({});
    const success = ref(false);

    const messageSend = () => {
      success.value = true;
      setTimeout(() => {
        success.value = false;
      }, 3000);
    };

    const getRegiones = () => {
      Axios.post(`/ns/simple/list`, formValues.value)
        .then((r) => {
          items.value = r.data.map((v: any) => ({
            label: v.name,
            value: v,
          }));
        })
        .catch(() => {
          // loading.value = false;
        });
    };
    const getProducts = () => {
      const b = document.querySelector('body')!.getAttribute('products');
      const _products = JSON.parse(b!);
      products.value = _products;
      console.log('Hola', _products);
    };
    onBeforeMount(() => {
      if (props.multiple) {
        getProducts();
      }
    });
    onMounted(() => {
      const rw = new URLSearchParams(location.search).get('regiones');
      if (rw) {
        try {
          const r = rw.split('_').map((v) => v.split(','));
          for (const [key, value] of r) {
            rewrite[key] = value;
          }
        } catch (error) {
          // else
        }
      }

      getRegiones();
    });
    const onSend = () => {
      loading.value = true;
      const data: any = formValues.value;
      const path = props.multiple
        ? `/ns/simple/${data.key}/submit`
        : `${location.pathname}/submit`;

      Axios.post(path, {
        ...data,
        city: data.region.name,
        region: rewrite[data.region.id] || data.region.id,
        extraInfo: {
          referido: data.referido,
          referidoTipo: data.referidoTipo,
          referidoCelular: data.referidoCelular,
          referidoCarnet: data.referidoCarnet,
        },
      })
        .then(() => {
          loading.value = false;
          (root as any).$formulate.reset('simple');
          messageSend();
        })
        .catch(() => {
          loading.value = false;
        });
    };
    return {
      formValues,
      messages,
      onSend,
      loading,
      items,
      products,
      success,
      formReferido,
      itemsReferido,
    };
  },
});
